import { useState, useEffect, createContext } from "react"
import { navigate } from "gatsby"

// Utils
import { getUUIDFromUrl } from "utils/browser"
import { saveOnSessionStorage, getFromSessionStorage } from "utils/storage"

const initialState = {
  auth: {},
  currentFormStep: "",
  leadUUID: "",
  nextFormStep: "",
  previousFormStep: "",
}

const Context = createContext({})

const SimulationProvider = ({ children }) => {
  const [currentSimulation, setCurrentSimulation] = useState(initialState)

  const setSimulation = (data) => {
    setCurrentSimulation(data)
    saveOnSessionStorage({ name: "simulation", value: data })
  }

  useEffect(() => {
    const simulationFromSessionStorage =
      getFromSessionStorage({ name: "simulation" }) || null
    const uuid = getUUIDFromUrl()

    const hasSavedSimulation =
      simulationFromSessionStorage &&
      Object.keys(simulationFromSessionStorage).length > 0

    if (uuid && hasSavedSimulation) {
      setCurrentSimulation(simulationFromSessionStorage)
    } else {
      navigate(`/`)
    }
  }, [])

  return (
    <Context.Provider value={{ simulation: currentSimulation, setSimulation }}>
      {children}
    </Context.Provider>
  )
}

export { SimulationProvider, Context }
