import { ga } from "@mobi/libraries/analytics"

const tracking = {
  pageLoad: ({ pageName, custom }) => {
    ga.page({
      custom: custom,
      page: {
        nome: `IT:NL:NCC:Veiculos:Simulacao:${pageName}`,
      },
      site: {
        negocio: "PF",
        tipoDeCanal: "Web",
      },
      rule: "pageLoad",
    })
  },

  buttonClickGa: ({ eventName }) => {
    ga.track("Button Click", {
      category: "Clique",
      action: "Simulacao",
      label: `BTN:Simulacao:${eventName}`,
      noInteraction: false,
    })
  },
}

export default tracking
