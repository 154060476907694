// JS
import "utils/bootstrap"
import { configure } from "@mobi/ds"

import analytics from "@mobi/libraries/analytics"
import { GTM, TAGULAR } from "gatsby-env-variables"
import trackings from "tracking"

// Contexts
import { SimulationProvider } from "./src/contexts/simulation"

// Styles
import "styles/index.scss"

analytics.setup({
  GTMId: GTM,
  makeTagular: { writeKey: TAGULAR.WRITE_KEY, sourceKey: TAGULAR.SOURCE_KEY },
  trackings,
})

configure({
  fonts: {
    ItauText: [300, 400, 700, 900],
    ItauDisplay: [300, 700, 900],
  },
})

export const wrapRootElement = ({ element }) => (
  <SimulationProvider>{element}</SimulationProvider>
)
