import createStorage from "@mobi/utils/browser/Storage"
import isClient from "@mobi/utils/browser/isClient"

export const storage = createStorage({ prefix: "vehicles" })

export const saveOnSessionStorage = ({ name = "", value }) => {
  return storage.session.setItem(name, value)
}

export const getFromSessionStorage = ({ name }) => {
  if (!isClient()) return ""
  return storage.session.getItem(name)
}

export const removeFromSessionStorage = ({ name }) => {
  return storage.session.removeItem(name)
}

export const removeFromSessionStorageWithoutPrefix = ({ name }) => {
  return sessionStorage.removeItem(name)
}
